export const ui = {
  setWidth: "SET_WIDTH",
  setHeight: "SET_HEIGHT",
  setScroll: "SET_SCROLL",
  setHeaderVisibility: "SET_HEADER_VISIBILITY",
  setHeaderSize: "SET_HEADER_SIZE",
  setFooterSize: "SET_FOOTER_SIZE",
  setHeaderActive: "SET_HEADER_ACTIVE",
  RandomGalleryLoading: "UI/RANDOM-GALLERY-LOADING",
};

export const api = {
  RandomGallery: "RANDOM-GALLERY",
};
