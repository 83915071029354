import { ui } from "../constants/reducers";

const state = {
  width: window.innerWidth,
  scroll: window.scrollY,
  height: window.innerHeight,
  isHeaderVisible: false,
  headerSize: 0,
  footerSize: 0,
  headerActive: false,
};

export const uiReducer = (initialState = state, action) => {
  const { type, data } = action;
  switch (type) {
    case ui.setWidth:
      return { ...initialState, width: data };
    case ui.setHeight:
      return { ...initialState, height: data };
    case ui.setScroll:
      return { ...initialState, scroll: data };
    case ui.setHeaderVisibility:
      return { ...initialState, isHeaderVisible: data };
    case ui.setHeaderSize:
      return { ...initialState, headerSize: data };
    case ui.setFooterSize:
      return { ...initialState, footerSize: data };
    default:
      return initialState;
  }
};
