import React from "react";
import constants from "../../../constants/constants";

const { imageBase, imageAltText } = constants;

const Fallback = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        zIndex: 999,
        background: "#1113",
      }}
    >
      <figure style={{ width: 175, height: 175 }}>
        <img
          src={`${imageBase}loading.gif`}
          alt={imageAltText}
          style={{ background: "#fff", borderRadius: 5, padding: 10 }}
        />
      </figure>
    </div>
  );
};

export default Fallback;
