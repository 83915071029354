import {
  AndroidOutlined,
  CameraOutlined,
  DribbbleOutlined,
  FontColorsOutlined,
  InstagramOutlined,
  PrinterOutlined,
  SoundOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
const iconMargin = 4;
const iconStyle = { marginRight: iconMargin };

const serviceList = [
  {
    key: "branding",
    title: "Branding",
    icon: <FontColorsOutlined style={iconStyle} />,
    background: "#97C1A9",
  },
  {
    key: "social-media-marketing",
    title: "Social Media Marketing",
    icon: <InstagramOutlined style={iconStyle} />,
    background: "#DFAAF1",
  },
  {
    key: "web-development",
    title: "Web Development",
    icon: <DribbbleOutlined style={iconStyle} />,
    background: "#FFB8B1",
  },
  {
    key: "app-development",
    title: "App Development",
    icon: <AndroidOutlined style={iconStyle} />,
    background: "#9AB7D3",
  },
  {
    key: "print-design",
    title: "Print Design",
    icon: <PrinterOutlined style={iconStyle} />,
    background: "#55CBCD",
  },
  {
    key: "photoshoot",
    title: "Photoshoot",
    icon: <CameraOutlined style={iconStyle} />,
    background: "#606060",
  },
  {
    key: "promotion",
    title: "Promotion",
    icon: <SoundOutlined style={iconStyle} />,
    background: "#FF9AA2",
  },
  {
    key: "influencer-marketing",
    title: "Influencer Marketing",
    icon: <UserAddOutlined style={iconStyle} />,
    background: "#C2F073",
  },
];

const whyDigiNull = [
  {
    title: "Accurate",
    subTitle: "Guidance",
    description:
      "DIgiNull provides appropriate solutions for your growth on digital environment by using concepts and benefits of social media and internet.",
  },
  {
    title: "Upgraded & Modern",
    subTitle: "Approach",
    description:
      "DigiNull looks forward to create innovative designs and palettes which can attract to users of any era or target audience specified by you.",
  },
  {
    title: "Digital Solutions",
    subTitle: "Under One Roof",
    description:
      "DigiNull comes with and implementation based ideas to provide resultant approach to offer potential growth to their customer on digital platforms.",
  },
];

const regex = {
  email: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
};

const constants = {
  imageBase: "/assets/images/",
  imageAltText: "404 - Not Found",
  services: serviceList,
  regex,
  why: whyDigiNull,
};
export default constants;
