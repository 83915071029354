import { api } from "../constants/reducers";

const state = {
  RandomGallery: {
    oneByTwo: [],
    twoByOne: [],
    square: [],
  },
};

const apiReducer = (initialState = state, action) => {
  const { type, data } = action;

  switch (type) {
    case api.RandomGallery:
      return { ...initialState, RandomGallery: data };
    default:
      return initialState;
  }
};

export default apiReducer;
